<template>
    <div class="container">
        <div v-html="other_page"></div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    computed: mapState({
        other_page: state => state.banner.other_page
    }),
    methods: {
        ...mapMutations([
            "setOtherPage"
        ]),
        ...mapActions([
            "getOtherPage"
        ]),
    },
    beforeRouteEnter (to, from, next) {
        let id = "";
        switch (to.name) {
            case "about":
                id = 1;
                break;
            case "contact":
                id = 2;
                break;
            case "join":
                id = 3;
                break;
            case "history":
                id = 4;
                break;
        }
        next(vm => {
            vm.getOtherPage(id);
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setOtherPage("");
        next();
    }
}
</script>

<style scoped>

</style>